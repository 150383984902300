@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.application
  .container
    .k-bullet
      padding-top: 15px

.k-bullet-left
  position: absolute
  margin-top: 5px
  margin-left: 5px
